import { buildMediaQueries, ShopCTAButton } from '@rategravity/marketing-components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../modules/colors';

const Anchor = styled.a`
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  ${buildMediaQueries('mediumAndDown', {
    width: '75%',
    marginBottom: '20px'
  })}
`;

export const AquaSection = styled.div`
  background-color: ${colors.AQUA};
`;

const CTAButton = styled(ShopCTAButton)`
  display: inline-block;
  width: 215px;
  background-color: ${colors.LOGO_SHADOW};
  border-color: ${colors.LOGO_SHADOW};
  color: ${colors.WHITE};
  :hover {
    background-color: ${colors.PRIMARY};
    border-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
  }
  ${buildMediaQueries('mediumAndDown', {
    width: '100%'
  })}
`;

const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  ${buildMediaQueries('mediumAndDown', {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60px'
  })}
`;

export const AnchoredButton = ({
  link,
  action
}: {
  link: string;
  action: 'Buying' | 'Refinance';
}) => (
  <Anchor href={`${link}/${action.toLocaleLowerCase()}`} aria-label={`${action} with Own Up!`}>
    <CTAButton>{action === 'Buying' ? action : 'Refinancing'}</CTAButton>
  </Anchor>
);

export const CallToAction = () => (
  <CallToActionWrapper>
    <AnchoredButton link={'/onboarding'} action="Buying" />
    <AnchoredButton action="Refinance" link={`${process.env.GATSBY_ONBOARDING}`} />
  </CallToActionWrapper>
);
